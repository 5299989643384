/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PerformanceBenchMarkDto } from './performance-bench-mark-dto';


export interface CreateLeadMeasureDto { 
    lagMeasureId: number;
    desiredDirectionConstant: CreateLeadMeasureDto.DesiredDirectionConstantEnum;
    currentValue: number;
    lagPredictionNote: string;
    measurementUnit: string;
    performanceBenchMarkDtos?: Array<PerformanceBenchMarkDto>;
    userIds: Array<string>;
}
export namespace CreateLeadMeasureDto {
    export type DesiredDirectionConstantEnum = 'UP' | 'DOWN';
    export const DesiredDirectionConstantEnum = {
        UP: 'UP' as DesiredDirectionConstantEnum,
        DOWN: 'DOWN' as DesiredDirectionConstantEnum
    };
}



/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CompletionReportSearchFilter { 
    lagIds?: Array<number>;
    reportType?: CompletionReportSearchFilter.ReportTypeEnum;
    date?: string;
    wigId?: number;
    offset?: number;
    limit?: number;
    orderColumn?: string;
}
export namespace CompletionReportSearchFilter {
    export type ReportTypeEnum = 'COMMITMENT' | 'KPI';
    export const ReportTypeEnum = {
        COMMITMENT: 'COMMITMENT' as ReportTypeEnum,
        KPI: 'KPI' as ReportTypeEnum
    };
}



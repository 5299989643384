/**
 * eCadence API
 * The eCadence RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateBlockerDto { 
    description: string;
    blockerTypeId: number;
    wasteType: CreateBlockerDto.WasteTypeEnum;
    possibleInfluencer?: string;
    blockerId?: string;
    lagIds: Array<number>;
}
export namespace CreateBlockerDto {
    export type WasteTypeEnum = 'TIME' | 'INVENTORY' | 'MOTION' | 'WAITING' | 'OVER_PRODUCTION' | 'OVER_PROCESSING' | 'DEFECT';
    export const WasteTypeEnum = {
        TIME: 'TIME' as WasteTypeEnum,
        INVENTORY: 'INVENTORY' as WasteTypeEnum,
        MOTION: 'MOTION' as WasteTypeEnum,
        WAITING: 'WAITING' as WasteTypeEnum,
        OVER_PRODUCTION: 'OVER_PRODUCTION' as WasteTypeEnum,
        OVER_PROCESSING: 'OVER_PROCESSING' as WasteTypeEnum,
        DEFECT: 'DEFECT' as WasteTypeEnum
    };
}


